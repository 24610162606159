<!--
 * @Author: lbh
 * @Date: 2022-12-24 12:10:25
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:12:28
 * @Description: file content
-->
<template>
  <div class="edit-qrCodeGraphicText4-box">
    <div class="cell-b">
      <selfCell title="展示類型">
        <el-select
          v-model="configs.type"
          placeholder="请选择"
          @change="setValue('type')"
        >
          <el-option
            label="類型1"
            value="1"
          />
          <el-option
            label="類型2"
            value="2"
          />

        </el-select>
      </selfCell>

      <selfCell
        class="cell-b"
        title="標題"
      >
        <div>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
            type="textarea"
            rows="3"
          />
        </div>
      </selfCell>
      <selfCell
        class="cell-b"
        title="副標題"
      >
        <div>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}subTitle`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`${lItem.code=='HK'?'':lItem.code}subTitle`)"
            type="textarea"
            rows="3"
          />
        </div>
      </selfCell>
    </div>
    <div class="cell-b">
      <h3>
        編輯子項<selfTips :content="$tipText.textWrap"></selfTips>
      </h3>
      <selfCell title="文字居中">
        <el-switch
          v-model="configs.textCenter"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="setValue('textCenter')"
        >
        </el-switch>
      </selfCell>
      <div class="cell-b">
        <selfCell title="背景顏色">
          <el-color-picker
            v-model="configs.itemsBgL"
            show-alpha
            @input="setValue('itemsBgL')"
          ></el-color-picker>
          <el-color-picker
            v-model="configs.itemsBgR"
            show-alpha
            @input="setValue('itemsBgR')"
          ></el-color-picker>
        </selfCell>
      </div>
      <div
        class="cell-b"
        v-for="(item,index) in configs.items"
        :key="index"
      >
        <div class="line">
          <selfUpload
            v-model="item.icon"
            type="default"
            style="width:80px;"
            @change="setValue('items')"
          />

          <!-- 操作 -->
          <el-button-group class="btn-group">
            <el-button
              type="primary"
              icon="el-icon-upload2"
              :disabled="index == 0"
              size="mini"
              @click="itemCheck(index,index-1)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-download"
              :disabled="index == configs.items.length-1"
              size="mini"
              @click="itemCheck(index,index+1)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="configs.items.length == 1"
              @click="itemDel(index)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-plus"
              size="mini"
              @click="itemAdd(index)"
            ></el-button>
          </el-button-group>
        </div>
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue('items')"
            type="textarea"
            rows="3"
          />
        </div>
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}content`]"
            type="textarea"
            :placeholder="`請輸入-${lItem.name}`"
            rows="4"
            @input="setValue('items')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          type: '',
          items: []
        };
      },
    },
    index: {
      default () {
        return '';
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
.edit-qrCodeGraphicText4-box {
  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>